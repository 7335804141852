import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import { FiEdit } from 'react-icons/fi';
import { useLocation } from './Context';
import LocationIcon from '../CurrentLocation';
import * as geoappui from "ui";
import { useIsMobile } from '@/hooks/useIsMobile';
interface LocationSelectorProps {
  apiKey: string;
  customClassName?: string; // Custom class for styling
  showEditIcon?: boolean; // Option to display the edit icon
  searchLocation?: any;
  setSearchLocation?: any;
  onSelect?: () => void;
  hideHeader?: boolean;
  initEditMode?: boolean;
  isEditingCallback?: (value: boolean) => void;
}
const LocationSelector: React.FC<LocationSelectorProps> = ({
  apiKey,
  customClassName = '',
  showEditIcon = true,
  onSelect,
  initEditMode = false,
  isEditingCallback,
  hideHeader = false
}) => {
  const {
    location,
    setLocation
  } = useLocation();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [locationInput, setLocationInput] = useState<string>('');
  const [debouncedLocation] = useDebounce(locationInput, 500); // 500ms debounce
  const [cache, setCache] = useState<Map<string, string[]>>(new Map());
  const [isEditing, setIsEditing] = useState<boolean>(initEditMode);
  useEffect(() => {
    if (location) {
      setLocationInput(location?.location);
    }
  }, [location]);
  useEffect(() => {
    isEditingCallback && isEditingCallback(isEditing);
  }, [isEditing]);
  useEffect(() => {
    const fetchSuggestions = async (input: string) => {
      if (!input || input.length < 3) {
        setSuggestions([]);
        return;
      }
      if (cache.has(input)) {
        setSuggestions(cache.get(input)!);
        return;
      }
      try {
        // TODO: Migrate to here api? 
        const response = await axios.get(`/api/google-places`, {
          params: {
            input
          }
        });
        // console.log({
        //   response
        // })
        const results = response.data.predictions.map((pred: any) => pred.description);
        setSuggestions(results);
        setCache(new Map(cache).set(input, results)); // Cache the results
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
      }
    };
    fetchSuggestions(debouncedLocation);
  }, [debouncedLocation, apiKey, cache]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationInput(e.target.value);
  };
  const handleSuggestionClick = (suggestion: string) => {
    // console.log({
    //   suggestion
    // })
    setLocation({
      location: suggestion,
      updateMap: true
    });
    setLocationInput(suggestion);
    setSuggestions([]); // Close the suggestions box
    setIsEditing(false);
    onSelect && onSelect();
  };
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };
  const isMobile = useIsMobile();
  return <div className={`px-4 max-w-lg ${customClassName}`} data-sentry-component="LocationSelector" data-sentry-source-file="Selector.tsx">
      {/* style={{ backgroundColor: "blue" }} */}
      {!hideHeader ? <div className="flex items-center justify-between">
        <label className="block text-gray-700 text-2xl font-bold my-4">
          Location
        </label>
        <div className='flex items-center justify-between'>
          {/* {!isEditing ? <LocationIcon className="pr-1" onLocationSuccess={(position) => {
                if (position) {
                  const { latitude, longitude } = position.coords;
                  // console.log("User's location:", { latitude, longitude });
                  geoappui.fetchCity(longitude, latitude).then((city) => {
                    if (city?.place_name) {
                      setLocation({
                        location: city?.place_name,
                        updateMap: true
                      })
                    }
                  })
                }
           }}/> : <></>} */}
          {showEditIcon && <FiEdit className="text-gray-500 cursor-pointer ml-2 mr-1" onClick={toggleEditMode} />}
        </div>
      </div> : <></>}

      {isEditing ? <>
          <input type="text" value={locationInput} onChange={handleInputChange} className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter your location" />
          {suggestions.length > 0 && <ul className="bg-white border border-gray-300 mt-2 rounded-lg shadow-lg">
              {suggestions.map((suggestion, index) => <li key={index} onClick={() => handleSuggestionClick(suggestion)} className="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer">
                  {suggestion}
                </li>)}
            </ul>}
        </> : <>
          <div className={"text-gray-900 bg-gray-100 py-2 rounded-lg " + (isEditing ? "border border-gray-300" : "") + (!isMobile ? "text-gray-700" : "text-white")}> 
            {location?.location || 'No location selected'} 
          </div>
        </>}
    </div>;
};
export default LocationSelector;