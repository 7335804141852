import React, { useState, useEffect, useRef } from 'react';
interface MediaComponentProps {
  media: Array<{
    type: 'image' | 'video' | 'gallery';
    url: string[];
  }>;
  showSingleItem?: boolean; // Prop to control single item view
}
const MediaComponent: React.FC<MediaComponentProps> = ({
  media,
  showSingleItem = false
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imgSrcErrorIndices = useRef<number[]>([]);

  // Handle escape key to exit full-screen mode
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreen) {
        setIsFullScreen(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreen]);

  // Toggle full-screen popup when media is clicked
  const toggleFullScreen = (index: number) => {
    setCurrentIndex(index);
    setIsFullScreen(true);
  };

  // Render media items (images, videos, or gallery)
  const renderMediaItem = (item: {
    type: 'image' | 'video' | 'gallery';
    url: string[];
  }, index: number) => {
    if (item.type === 'video') {
      return <div key={index} className="media-item video w-full" onClick={() => toggleFullScreen(index)}>
          <video controls className="w-full h-auto rounded-lg">
            <source src={item.url[0]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>;
    } else if (item.type === 'image') {
      if (imgSrcErrorIndices.current.indexOf(index) > -1) return;
      return <div key={index} className="media-item image w-full" onClick={() => toggleFullScreen(index)}>
          <img src={item.url[0]} alt={`media-${index}`} onError={() => imgSrcErrorIndices.current.push(index)} className="w-full h-auto object-cover rounded-lg" />
        </div>;
    }
    return null;
  };

  // Full-screen popup view
  const renderFullScreenMedia = () => {
    const item = media[currentIndex];
    return <div
    // style={{ left: "290px" }}
    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 backdrop-blur-sm pt-10" onClick={() => setIsFullScreen(false)} // Close when clicking on background
    data-sentry-component="renderFullScreenMedia" data-sentry-source-file="Media.tsx">
        {/* Prevent click on media from closing fullscreen */}
        <div className="max-w-full-lg max-h-full p-4 relative" onClick={e => e.stopPropagation()}>
          {/* <button
            className="absolute top-[150px] right-4 text-white text-2xl z-10"
            onClick={() => setIsFullScreen(false)}
           >
            &times;
           </button> */}
          {item.type === 'video' ? <video controls className="max-w-full max-h-[calc(100vh-100px)] object-contain">
              <source src={item.url[0]} type="video/mp4" />
              Your browser does not support the video tag.
            </video> : <img src={item.url[0]} alt={`media-fullscreen-${currentIndex}`} className="max-w-full max-h-[calc(100vh-100px)] object-contain" />}
        </div>
      </div>;
  };

  // Display either the first item or all media items
  const mediaToDisplay = showSingleItem ? media.slice(0, 1) : media;
  return <div className="media-container space-y-8" data-sentry-component="MediaComponent" data-sentry-source-file="Media.tsx">
      {mediaToDisplay.map((item, index) => renderMediaItem(item, index))}
      {isFullScreen && renderFullScreenMedia()}
    </div>;
};
export default MediaComponent;