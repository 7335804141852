import Link from 'next/link';
interface GoogleMapsLinkProps {
  lat: number;
  lng: number;
  label?: string; // Optional label for the marker
  placeIdUrl?: string;
}
const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({
  lat,
  lng,
  label,
  placeIdUrl
}) => {
  const baseUrl = 'https://www.google.com/maps';
  let placeLink;
  if (placeIdUrl) {
    placeLink = placeIdUrl;
  } else if (label) {
    // Use the label for geocoding and searching
    placeLink = baseUrl + `?q=${encodeURIComponent(label)}`;
  } else {
    // Fallback to latitude and longitude
    placeLink = baseUrl + `?q=${lat},${lng}`;
  }
  return <Link href={placeLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline pl-2" data-sentry-element="Link" data-sentry-component="GoogleMapsLink" data-sentry-source-file="OpenMaps.tsx">
        (Open Map)
      </Link>;
};
export { GoogleMapsLink };