import useSharedState from '@/hooks/useSharedState';
import { useState, useEffect, useRef, ReactElement } from 'react';
import Image from "next/image";
import pluralize from 'pluralize';
interface Icon {
  url: string;
  name: string;
}
interface Record {
  icon: Icon;
  id: string; // Assuming each record has a unique identifier
  // Other properties can be added here
}
interface PaginatedListProps<T> {
  records?: T[];
  fetchRecords?: (page: number, pageSize: number) => Promise<{
    records: T[];
    totalPages: number;
  }>;
  pageSize?: number;
  renderItem: (record: T) => ReactElement;
  orientation?: 'horizontal' | 'vertical';
  maxVisiblePages?: number; // Configurable max visible pages
  onRecordView?: (record: T) => void; // Callback for when a record is viewed
}
const pluralizeTypes = (word: string) => {
  if (word) {
    switch (word) {
      case 'Event':
      case 'Place':
      case 'Business':
      case 'Fundraiser':
      case 'Restroom':
        return pluralize(word);
    }
  }
  return word;
};
function PaginatedList<T extends Record>({
  records = [],
  fetchRecords,
  pageSize = 5,
  renderItem,
  orientation = 'vertical',
  maxVisiblePages = 5,
  // Default to 5 visible pages
  onRecordView
}: PaginatedListProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const savedPage = localStorage.getItem('lastPage');
    return savedPage ? Number(savedPage) : 1;
  });
  const [paginatedRecords, setPaginatedRecords] = useState<T[]>([]);
  const [selectedIcon, setSelectedIcon] = useSharedState('selectedIcon',
  // Retrieve the last selected icon from localStorage
  localStorage.getItem('lastSelectedIcon'));
  const uniqueIcons = useRef<any[]>();
  useEffect(() => {
    uniqueIcons.current = getUniqueIcons(records);
    const uniqueIconsNames = Array.from(uniqueIcons.current?.map(x => x?.name));
    if (selectedIcon == null && uniqueIconsNames?.length > 0) {
      setSelectedIcon(uniqueIconsNames[0]);
    }
    if (selectedIcon && uniqueIconsNames?.length && uniqueIconsNames.indexOf(selectedIcon) === -1) {
      setSelectedIcon(uniqueIconsNames.length > 0 ? uniqueIconsNames[0] : null);
    } else {
      setFilteredRecords(selectedIcon ? records.filter(record => record.icon.name === selectedIcon) : records);
    }
  }, [records, selectedIcon]);
  const [filteredRecords, setFilteredRecords] = useState<T[]>();
  const [totalPages, setTotalPages] = useState(1);
  const viewTimestamps = useRef<Map<string, number>>(new Map()); // Track timestamps for each record // TODO: Is there a better approach here?

  const observers = useRef<Map<number, IntersectionObserver>>(new Map());

  // Function to extract unique icons from records
  const getUniqueIcons = (recordsIn: any[]) => {
    return Array.from(new Set(recordsIn.map(record => record.icon.name))).map(iconName => {
      const icon = records.find(record => record.icon.name === iconName)?.icon;
      return icon ? {
        url: icon.url,
        name: icon.name
      } : null;
    }).filter(icon => {
      return icon !== null;
    }).sort((icon1, icon2) => {
      if (icon1.name === "Event") return -1; // "Event" comes first
      if (icon2.name === "Event") return 1; // "Event" comes first
      return -1 * icon2.name.localeCompare(icon1.name); // Normal sorting for others
    });
  };
  useEffect(() => {
    const recordsIn = filteredRecords || [];
    if (fetchRecords) {
      fetchPage(currentPage);
    } else {
      const totalRecords = recordsIn?.length || 0;
      setTotalPages(Math.ceil(totalRecords / pageSize));
      setPaginatedRecords(recordsIn.slice((currentPage - 1) * pageSize, currentPage * pageSize));
    }
    if (!filteredRecords?.length || currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [filteredRecords, currentPage]);
  useEffect(() => {
    localStorage.setItem('lastPage', currentPage.toString());
  }, [currentPage]);

  // Update localStorage when selectedIcon changes
  useEffect(() => {
    if (selectedIcon !== null) {
      localStorage.setItem('lastSelectedIcon', selectedIcon);
    } else {
      localStorage.removeItem('lastSelectedIcon');
    }
  }, [selectedIcon]);
  const fetchPage = async (page: number) => {
    if (fetchRecords) {
      const data = await fetchRecords(page, pageSize);
      setPaginatedRecords(data.records);
      setTotalPages(data.totalPages);
    }
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    scrollToTop(); // Scroll to top on page change
  };
  const scrollToTop = () => {
    const overlay = document.querySelector('.overlayRef'); // Use your overlay class name here
    if (overlay) {
      overlay.scrollTop = 0; // Scroll to the top
    }
  };
  const getVisiblePages = () => {
    const startPage = Math.max(Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1), 1);
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    return Array.from({
      length: endPage - startPage + 1
    }, (_, i) => startPage + i);
  };

  // Custom debounce logic per record, ensuring we don't call `onRecordView` for the same record within 2 seconds
  const debouncedOnRecordView = (record: T) => {
    if (!onRecordView) return;
    const now = Date.now();
    const lastViewed = viewTimestamps.current.get(record.id);
    if (!lastViewed || now - lastViewed >= 2000) {
      viewTimestamps.current.set(record.id, now);
      onRecordView(record);
    }
  };
  const observeRecord = (record: T, index: number) => {
    const targetElement = document.getElementById(`record-${index}`);
    if (!targetElement) return;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        // Change the threshold logic to require more of the element to be visible
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
          // Fire onRecordView only when at least 50% of the element is visible
          debouncedOnRecordView(record);
        }
      });
    }, {
      threshold: 0.5 // At least 50% of the element needs to be in view
    });
    observer.observe(targetElement);
    observers.current.set(index, observer);
  };
  const unobserveRecord = (index: number) => {
    const observer = observers.current.get(index);
    if (observer) {
      const targetElement = document.getElementById(`record-${index}`);
      if (targetElement) observer.unobserve(targetElement);
      observers.current.delete(index);
    }
  };
  useEffect(() => {
    // Unobserve old records and observe new ones when paginatedRecords changes
    observers.current.forEach((observer, index) => unobserveRecord(index));
    paginatedRecords.forEach((record, index) => observeRecord(record, index));
    if (records?.length && !paginatedRecords?.length && currentPage !== 1) {
      setCurrentPage(1);
    }
    return () => {
      observers.current.forEach((observer, index) => unobserveRecord(index));
    };
  }, [paginatedRecords]);
  const paginationControls = () => {
    return <>
      <div className="flex flex-wrap justify-center mt-4 w-full">
        <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} className="px-4 py-2 mr-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 disabled:opacity-50">
          Previous
        </button>
        {getVisiblePages().map(page => <button key={page} onClick={() => handlePageChange(page)} className={`px-4 py-2 mx-1 rounded-lg ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}>
            {page}
          </button>)}
        <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} className="px-4 py-2 ml-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 disabled:opacity-50">
          Next
        </button>
      </div>
    </>;
  };
  return <div className="w-full max-w-7xl mx-auto" data-sentry-component="PaginatedList" data-sentry-source-file="index.tsx">
      {/* Icon filter bar */}
      <div className="flex justify-center space-x-4 my-4">
        {/* <button
          onClick={() => setSelectedIcon(null)} // "All/No Filter" option
          className={`flex items-center p-2 rounded-lg ${selectedIcon === null ? 'underline shadow-lg text-blue-500' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
         >
          All
         </button> */}
        {uniqueIcons.current?.map(icon => <button key={icon?.name} onClick={() => setSelectedIcon(selectedIcon === icon?.name ? null : icon?.name)} className={`flex items-center p-2 mt-1 rounded-lg ${selectedIcon === icon?.name ? 'underline shadow-lg text-blue-500' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}>
            <Image src={icon?.url} alt={icon?.name} width={30} height={30} className="w-6 h-7 mr-2" />
            {pluralizeTypes(icon?.name)}
          </button>)}
      </div>

      {paginationControls()}

      {/* Display records */}
      <ul className={`${orientation === 'horizontal' ? 'flex space-x-4 overflow-x-auto' : 'grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4'}`}>
        {paginatedRecords.map((record, index) => <li key={index} id={`record-${index}`} className="bg-white p-4 rounded-lg shadow-md flex-shrink-0">
            {renderItem(record)}
          </li>)}
      </ul>

      {paginatedRecords?.length ? paginationControls() : <></>}

    </div>;
}
export { PaginatedList };