// src/context/ConfigContext.tsx
import { createContext, useContext } from "react";
const ConfigContext = createContext<Record<string, any> | null>(null);
export function ConfigProvider({
  config,
  children
}: {
  config: Record<string, any> | null;
  children: React.ReactNode;
}) {
  return <ConfigContext.Provider value={config} data-sentry-element="unknown" data-sentry-component="ConfigProvider" data-sentry-source-file="ConfigContext.tsx">{children}</ConfigContext.Provider>;
}
export function useConfig() {
  return useContext(ConfigContext);
}