// utils/apiClient.ts

type HttpMethod = 'GET' | 'POST';
export class ApiClient {
  private baseUrl: string;
  constructor() {
    // Get the base API URL from environment variables
    this.baseUrl = process.env.NEXT_PUBLIC_API_ENDPOINT || '';
    if (!this.baseUrl) {
      throw new Error('NEXT_PUBLIC_API_ENDPOINT is not set in environment variables');
    }
  }

  // Generic method to make API calls.
  private async request<T>(url: string, method: HttpMethod, body?: any): Promise<T> {
    const options: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      ...(body ? {
        body: JSON.stringify(body)
      } : {})
    };
    try {
      // TODO: use axios
      const response = await fetch(`${this.baseUrl}${url}`, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json() as Promise<T>;
    } catch (error) {
      if (error instanceof Error && error.message === 'Failed to fetch') {
        // Handle fetch-specific errors (e.g., network issues)
        throw new Error('Network error or server is unreachable. Please try again later.');
      } else {
        // Rethrow other errors
        throw error;
      }
    }
  }

  // GET method
  async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, 'GET');
  }

  // POST method
  async post<T>(endpoint: string, data?: any): Promise<T> {
    return this.request<T>(endpoint, 'POST', data);
  }

  // Add more methods here if needed
}