import branch from 'branch-sdk';
function generateBranchLink(page: any, id: any) {
  const linkData = {
    tags: ['tag1', 'tag2'],
    channel: 'website',
    feature: 'deep-link',
    stage: 'new user',
    data: {
      page,
      id
    }
  };

  // branch.createBranchUniversalObject('page', linkData).then((branchObj) => {
  //   branchObj.generateShortUrl({ type: '0' }).then(({ url }) => {
  //     console.log('Generated Branch URL:', url);
  //   });
  // });
}
function buildQueryString(data: Record<string, any>) {
  if (typeof data === "string") return data;
  const query: string[] = [];
  for (const key in data) {
    if (data[key] && typeof data[key] === "string") {
      data[key].replace(" ", "%20");
    }
    query.push(`${key}=${data[key]}`);
  }
  // Join each item in the array with a `&` and return the resulting string
  return encodeURI(query.join("&"));
}
export { generateBranchLink, buildQueryString };